export default [
  {
    path: '/all/transactions',
    name: 'all-transactions',
    component: () => import('@/views/reports/AllTransection.vue'),
    meta: {
      action: 'API_B2B_USER',
      resource: 'AccessBothUser',
      auth: true,
    },
  },
  {
    path: '/test/transactions',
    name: 'test-transactions',
    component: () => import('@/views/reports/TestTransection.vue'),
    meta: {
      action: 'API_B2B_USER',
      resource: 'AccessBothUser',
      auth: true,
    },
  },
  {
    path: '/all/lazada/transactions',
    name: 'all-lazada-transactions',
    component: () => import('@/views/reports/AllTransectionLazada.vue'),
    meta: {
      action: 'API_B2B_USER',
      resource: 'AccessBothUser',
      auth: true,
    },
  },
  {
    path: '/bill/payment',
    name: 'bill-payment',
    component: () => import('@/views/reports/BillPayment.vue'),
    meta: {
      action: 'API_B2B_USER',
      resource: 'AccessBothUser',
      auth: true,
    },
  },
  {
    path: '/archive/transactions',
    name: 'archive-transactions',
    component: () => import('@/views/reports/AllArchiveTransection.vue'),
    meta: {
      action: 'API_B2B_USER',
      resource: 'AccessBothUser',
      auth: true,
    },
  },
  {
    path: '/wallet/statement',
    name: 'wallet-summary',
    component: () => import('@/views/reports/WalletSummary.vue'),
    meta: {
      action: 'API_B2B_USER',
      resource: 'AccessBothUser',
      auth: true,
    },
  },
  {
    path: '/summary/info',
    name: 'summary-info',
    component: () => import('@/views/reports/SummaryInfo.vue'),
    meta: {
      action: 'API_B2B_USER',
      resource: 'AccessBothUser',
      auth: true,
    },
  },
  {
    path: '/archive/summary/info',
    name: 'archive-summary-info',
    component: () => import('@/views/reports/ArchiveSummaryInfo.vue'),
    meta: {
      action: 'API_B2B_USER',
      resource: 'AccessBothUser',
      auth: true,
    },
  },
  {
    path: '/export/history',
    name: 'export-history',
    component: () => import('@/views/reports/ExportHistory.vue'),
    meta: {
      action: 'API_B2B_USER',
      resource: 'AccessBothUser',
      auth: true,
    },
  },
]
