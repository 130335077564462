import Cookie from 'js-cookie'
import ApiService from './api.service'

export default {
  getCookie() {
    const token = Cookie.get('XSRF-TOKEN')

    if (token) {
      return new Promise(resolve => {
        resolve(token)
      })
    }

    return ApiService.get('/csrf-cookie')
  },
}
