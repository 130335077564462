/* eslint-disable class-methods-use-this */
import ApiService from '../common/api.service'
import StorageService from '../common/storage.service'
import decrypt from '../common/decrypt'

class ProfileService {
  getAuthUser() {
    return ApiService.get('profile').then(response => response.data, error => Promise.reject(error))
  }

  updateProfile(data) {
    return ApiService.post('/update/user/info', data).then(response => {
      const user = decrypt(response.data.data)
      const existing = StorageService.getStorage('user')
      existing.name = user.name
      StorageService.setStorage('user', existing)
      return user
    })
  }
}

export default new ProfileService()
