import SecureLS from 'secure-ls'

const ls = new SecureLS({ encodingType: 'aes', isCompression: false })

const getStorage = key => {
  try {
    return ls.get(key)
  } catch (e) {
    removeStorage()
  }
}

const setStorage = (key, value) => ls.set(key, value)

const removeStorage = key => {
  ls.remove(key)
}

const removeAllStorage = () => {
  ls.removeAll()
}

export default { getStorage, setStorage, removeStorage }
