import StorageService from './storage.service'

const TOKEN_KEY = 'token'

const getToken = () => StorageService.getStorage(TOKEN_KEY)

const saveToken = token => StorageService.setStorage(TOKEN_KEY, token)

const destroyToken = () => StorageService.removeStorage(TOKEN_KEY)

const getUser = () => StorageService.getStorage('user')

const isLogin = () => StorageService.getStorage('loggedIn')

export default {
  getToken, saveToken, destroyToken, getUser, isLogin,
}
