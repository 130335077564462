export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      	action: 'API_B2B_USER',
      resource: 'AccessBothUser',
      auth: true,
    },
  },
]
