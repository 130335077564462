export default [
  {
    path: '/profile',
    name: 'user-profile',
    component: () => import('@/views/user-settings/UserSettings.vue'),
    meta: {
      action: 'API_B2B_USER',
      resource: 'AccessBothUser',
      auth: true,
    },
  },
]
