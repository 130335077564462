import { Ability } from '@casl/ability'
import { initialAbility } from './config'
import JwtService from '../../common/jwt.service'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JwtService.getUser()
let ability = null
if (userData && userData.loginid == '60386011888') {
  console.log(userData.loginid)
	 ability = [
	  {
	  	action: 'lazada_USER',
	  	subject: 'AccessBothUser',
	  },
	   {
	  	action: 'API_B2B_USER',
	  	subject: 'AccessBothUser',
	  },
  ]
} else if (userData && userData.type == 'API') {
	 ability = [
	  {
	  	action: 'API_B2B_USER',
	  	subject: 'AccessBothUser',
	  },
  ]
} else if (userData && userData.type == 'Software') {
	 ability = [
	  {
	  	action: 'API_B2B_USER',
	  	subject: 'AccessBothUser',
	  },
	  {
	  	action: 'B2B',
	  	subject: 'B2BUser',
	  },
  ]
}
const existingAbility = userData ? ability : null
const abilityf = new Ability(existingAbility || initialAbility)
export default abilityf
