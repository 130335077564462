/* eslint-disable class-methods-use-this */
import ApiService from '../common/api.service'
import decrypt from '../common/decrypt'

class UserService {
  getUserInfo() {
    return ApiService.get('/user/info').then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  updateUserInfo(data) {
    return ApiService.post('/update/user/info', data).then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  validatePhone(data) {
    return ApiService.query('/valid/phone', data).then(response => Promise.resolve(response.data), error => Promise.reject(error))
  }

  requestOTP(data) {
    return ApiService.post('/send/otp', data).then(response =>
    //  var existing =  StorageService.getStorage('user');
    // existing['two_factor_expires_at'] = response.data.user.two_factor_expires_at;
    // StorageService.setStorage('user',existing)
      decrypt(response.data.data))
  }

  vaildSmsOTP(data) {
    return ApiService.query('/vaildate/otp', data).then(response => Promise.resolve(response.data), error => Promise.reject(error))
  }

  validateEmail(data) {
    return ApiService.query('/valid/email', data).then(response => Promise.resolve(response.data), error => Promise.reject(error))
  }

  requestEmailOTP(data) {
    return ApiService.post('/send/email/otp', data).then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  vaildEmailOTP(data) {
    return ApiService.query('/vaildate/email/otp', data).then(response => Promise.resolve(response.data), error => Promise.reject(error))
  }

  changeMobile(data) {
    return ApiService.post('change/mobile', data).then(response => Promise.resolve(response.data), error => Promise.reject(error))
  }

  changeEmail(data) {
    return ApiService.post('change/email', data).then(response => Promise.resolve(response.data), error => Promise.reject(error))
  }

  changePassword(user) {
    return ApiService.post('change/password', {
      old_password: user.old_password,
      password: user.password,
      password_confirmation: user.password_confirmation,
    }).then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  getDiscountPlanList(data) {
    return ApiService.query('/discount/plan/list', data).then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  changeDiscountPlanStatus(data) {
    return ApiService.post('/discount/plan/status/change', data).then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  getDiscountPlan(data) {
  	return ApiService.query('/discount/plan', data).then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  addAndEditDiscountPlan(data) {
    return ApiService.post('/discount/plan/add-edit', data).then(response => Promise.resolve(response.data), error => Promise.reject(error))
  }

  getUserBalnce(id) {
    return ApiService.query('/check/balnce', { loginid: id }).then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  changeStatus(user) {
    return ApiService.post('/user/status/change', { loginid: user.id, status: user.status }).then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  getUserRank(data) {
    return ApiService.query('/user/rank', data).then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  getUserPlan(data) {
    return ApiService.query('/user/plan', data).then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  getUserDiscountPlan(data) {
    return ApiService.query('/user/discount/plan', data).then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  getDownlineUsers(data) {
    return ApiService.query('/downline/users', data).then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  getDownlineUserInfo(id) {
    return ApiService.query('/downline/user/info', { id }).then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  getDownlineUserSlab(id) {
    return ApiService.query('/downline/user/slab', { plan_id: id }).then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  getDownlineUserPlan(id) {
    return ApiService.query('/downline/user/plan', { rank_id: id }).then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  getDownlineRankList(id) {
    return ApiService.get('/downline/user/ranklist').then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  getAuthUserPlanList(rankId) {
    return ApiService.query('downline/auth/user/plan-list', { rankId }).then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  addDownlineUser(data) {
    return ApiService.post('downline/user/add', data).then(response => Promise.resolve(response.data), error => Promise.reject(error))
  }

  updateDownlineUserInfo(data) {
    return ApiService.post('/downline/user/info/update', data).then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  addOrUpdateBusinessDetails(data) {
    return ApiService.post('/user-business-details', data).then(response => Promise.resolve(response.data), error => Promise.reject(error))
  }

  getBusinessDetailseData() {
    return ApiService.get('/user-business-details').then(response => Promise.resolve(response.data), error => Promise.reject(error))
  }
}

export default new UserService()
