import CryptoJS from 'crypto-js'
import serialize from './serialize'
import format from './format'
import { APP_KEY } from './config'
import StorageService from './storage.service'

const encrypt = payload => {
  const key = (StorageService.getStorage('anyendashkey') != '') ? StorageService.getStorage('anyendashkey') : APP_KEY
  return CryptoJS.AES.encrypt(serialize(payload), CryptoJS.enc.Base64.parse(key), {
    format: format(key),
    iv: CryptoJS.lib.WordArray.random(16),
  }).toString()
}

export default encrypt
