import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import encrypt from './encrypt'
import { API_URL } from './config'

const init = () => {
  Vue.use(VueAxios, axios)
  Vue.axios.defaults.baseURL = API_URL
  Vue.axios.defaults.withCredentials = true
}

const finalRequest = (method, resource, data, params) => Vue.axios.request({
  url: resource,
  params: { payload: params },
  data: { payload: data },
  method,
  headers: { 'X-REQUEST-ENCRYPTED': true },
})

const query = (resource, params = {}) => finalRequest('get', resource, '', encrypt(params))

const get = resource => finalRequest('get', resource, '')

const post = (resource, data) => finalRequest('post', resource, encrypt(data))

const patch = (resource, data) => finalRequest('patch', resource, data)

const login = (resource, data) => finalRequest('post', resource, encrypt(data))

const put = (resource, data) => finalRequest('put', resource, data)

const destroy = resource => finalRequest('delete', resource)

export default {
  init, query, get, post, patch, put, login, destroy,
}
