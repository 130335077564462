import AuthService from '../../services/auth.service'
import UserService from '../../services/user.service'
import ProfileService from '../../services/profile.service'
import JwtService from '../../common/jwt.service'
import StorageService from '../../common/storage.service'

const state = {
  user: {},
  isAuthenticated: !!JwtService.isLogin(),
}

const getters = {
  currentUser(state) {
    if (JwtService.isLogin()) {
      state.user = JwtService.getUser()
    }
    return state.user
  },
  isAuthenticated(state) {
    return state.isAuthenticated
  },
}

const actions = {
  login({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AuthService.login(payload.userDetails.loginId, payload.userDetails.password)
        .then(response => {
          if (response) {
            commit('loginSuccess', response)

            resolve(response)
          } else {
            reject({ message: 'Wrong Email or Password' })
          }
        })
        .catch(error => { reject(error) })
    })
  },
  logout({ commit }) {
    AuthService.logout()
    commit('logout')
  },
  forgotPassword({ commit }, user) {
    return AuthService.forgotPassword(user).then(
      user => {
        commit('forgotPassword')
        return Promise.resolve(user)
      },
      error => {
        commit('forgotPassword')
        return Promise.reject(error)
      },
    )
  },
  resetPassword({ commit }, user) {
    return AuthService.resetPassword(user).then(
      response => {
        commit('resetPasswordSuccess')

        return Promise.resolve(response.data)
      },
      error => {
        commit('resetPasswordFailure')
        return Promise.reject(error)
      },
    )
  },
  updateProfile({ commit }, user) {
    return ProfileService.updateProfile(user).then(
      response => {
        const existing = StorageService.getStorage('user')
        existing.is_new_user = 0
        StorageService.setStorage('user', existing)
        commit('updateProfile', response)
        return Promise.resolve(response)
      },
      error => Promise.reject(error),
    )
  },
  register({ commit }, user) {
    return AuthService.register(user).then(
      response => {
        commit('registerSuccess')
        return Promise.resolve(response.data)
      },
      error => {
        commit('registerFailure')
        return Promise.reject(error)
      },
    )
  },
  verifyTwoFactorCode({ commit }, data) {
    return AuthService.verifyTwoFactorCode(data).then(
      response => {
        commit('varifytoken')
        return Promise.resolve(response.data)
      },
      error => Promise.reject(error),
    )
  },
  sendTwoFactorCode({ commit }, data) {
    return UserService.requestOTP(data)
      .then(response => {
        const existing = StorageService.getStorage('user')
        existing.two_factor_expires_at = response.two_factor_expires_at
        StorageService.setStorage('user', existing)
        commit('resendTwoFactorCodeSuccess', response)
        return response
      })
  },
}
const mutations = {
  loginSuccess(state, user) {
    state.isAuthenticated = true
    state.user = user
  },
  loginFailure(state) {
    state.isAuthenticated = false
    state.user = null
  },
  logout(state) {
    state.isAuthenticated = false
    state.user = null
  },
  updateProfile(state, user) {
    state.user.name = user.name
    state.user.is_new_user = 0
  },
  forgotPassword(state) {
    state.isAuthenticated = false
  },
  resetPasswordSuccess(state) {
    state.isAuthenticated = false
  },
  resetPasswordFailure(state) {
    state.isAuthenticated = false
  },
  registerSuccess(state) {
    state.isAuthenticated = false
  },
  registerFailure(state) {
    state.isAuthenticated = false
  },
  varifytoken(state) {
    state.isAuthenticated = true
    state.user.two_factor_expires_at = null
  },
  resendTwoFactorCodeSuccess(state, user) {
    state.isAuthenticated = true
    state.user.two_factor_expires_at = user.two_factor_expires_at
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
